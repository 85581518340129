var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payment-confirmations"
  }, [_c('sygni-container-title', [_vm._v("Payment confirmations")]), _c('file-uploader', {
    staticClass: "mt-5",
    attrs: {
      "supported-file-formats": _vm.supportedFileFormats,
      "uploadProgress": _vm.uploadProgress,
      "dropAreaText": "Drag and drop to add payment confirmation",
      "infoText": "File format: pdf, doc / max 5mb",
      "multiple": false
    },
    on: {
      "input": function input($event) {
        return _vm.addFiles($event);
      }
    }
  }), _c('div', {
    staticClass: "payment-confirmations__table"
  }, [_c('b-table', {
    ref: "paymentConfirmationsTable",
    staticClass: "sygni-b-table mt-4",
    attrs: {
      "innertable": "true",
      "fields": [{
        key: 'fileName',
        sortable: false,
        class: 'file-name text-left'
      }, {
        key: 'actions',
        sortable: false,
        class: 'actions text-right',
        label: 'Actions'
      }],
      "items": _vm.items,
      "show-empty": true,
      "busy": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "cell(fileName)",
      fn: function fn(rowData) {
        var _rowData$item, _rowData$item2;

        return [_c('div', {
          staticClass: "cell"
        }, [_c('div', [_vm._v(_vm._s(rowData !== null && rowData !== void 0 && (_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.fileName ? rowData === null || rowData === void 0 ? void 0 : (_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.fileName : ''))])])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell"
        }, [_c('div', {
          staticClass: "td"
        }, [_c('div', {
          staticClass: "td__actions"
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Preview',
            expression: "'Preview'",
            modifiers: {
              "hover": true
            }
          }],
          class: ['action', !_vm.hasPreview(rowData === null || rowData === void 0 ? void 0 : rowData.item) ? 'action--disabled' : ''],
          on: {
            "click": function click($event) {
              return _vm.previewPaymentConfirmation(rowData === null || rowData === void 0 ? void 0 : rowData.item);
            }
          }
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('Fund'),
            "alt": "Preview"
          }
        })])]), _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Download',
            expression: "'Download'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action",
          on: {
            "click": function click($event) {
              return _vm.downloadGeneratedFileByUrl(rowData === null || rowData === void 0 ? void 0 : rowData.item);
            }
          }
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('DOWNLOAD'),
            "alt": "Download"
          }
        })])]), _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Delete',
            expression: "'Delete'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action action--delete",
          on: {
            "click": function click($event) {
              return _vm.openPaymentConfirmationModal(rowData === null || rowData === void 0 ? void 0 : rowData.item);
            }
          }
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "15.605",
            "height": "18",
            "viewBox": "0 0 15.605 18"
          }
        }, [_c('path', {
          staticStyle: {
            "fill": "currentColor"
          },
          attrs: {
            "d": "M8.188.529a1.8 1.8 0 0 0-1.273.528l-.672.672H3.389a1.807 1.807 0 0 0-1.351.576 1.829 1.829 0 0 0-.453 1.224 1.83 1.83 0 0 0 .453 1.225 1.717 1.717 0 0 0 .749.48v1.895a.6.6 0 1 0 1.2 0V5.33h10.8v11.4a.586.586 0 0 1-.6.6h-9.6a.586.586 0 0 1-.6-.6v-6a.6.6 0 1 0-1.2 0v6a1.809 1.809 0 0 0 1.8 1.8h9.6a1.809 1.809 0 0 0 1.8-1.8V5.235a1.717 1.717 0 0 0 .75-.481 1.83 1.83 0 0 0 .453-1.225 1.829 1.829 0 0 0-.453-1.224 1.807 1.807 0 0 0-1.351-.576h-2.853l-.673-.672a1.8 1.8 0 0 0-1.272-.528zm0 1.2h2.4a.6.6 0 0 1 .424.176l.848.848a.6.6 0 0 0 .424.176h3.1a.533.533 0 0 1 .455.174.685.685 0 0 1 0 .853.533.533 0 0 1-.455.174h-12a.533.533 0 0 1-.455-.174.685.685 0 0 1 0-.853.533.533 0 0 1 .455-.174h3.1a.6.6 0 0 0 .424-.176l.848-.848a.6.6 0 0 1 .432-.176zM6.978 7.72a.6.6 0 0 0-.591.609v6a.6.6 0 1 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609z",
            "transform": "translate(-1.585 -.529)"
          }
        })])])])])])])];
      }
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "cell cell--empty"
        }, [_vm._v("The are no payment confirmations yet.")])];
      },
      proxy: true
    }])
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticClass: "text-center text-danger spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.deletingPaymentConfirmationId,
      expression: "deletingPaymentConfirmationId"
    }],
    attrs: {
      "isLoading": _vm.isLoading,
      "header": "Are you sure?",
      "description": "Do you really want to delete this payment confirmation?",
      "cancelText": "No",
      "confirmText": "Yes"
    },
    on: {
      "close": _vm.closeDeleteConfirmationModal,
      "cancel": _vm.closeDeleteConfirmationModal,
      "confirm": _vm.deletePaymentConfirmation
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }